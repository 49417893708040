import React from 'react'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function BusinessCard({ image, title, text }) {
    return (
        <div className="flex flex-col  gap-[20px] w-auto h-auto">
            <div className={`w-auto ${image === null ? 'bg-[#F4F4F4]' : ''} h-[278px] overflow-hidden`}>
                {image !== null && <img src={image} alt="img of the mine" className="w-[100%] h-[100%] object-cover" />}
            </div>
            <div className="flex flex-col items-start gap-[30px]">
                <div className="flex flex-col items-start gap-[6px]">
                    <span className="lg:text-[25px] sm:text-[18px] font-bold text-black">{title}</span>
                    <span className="lg:text-[18px] sm:text-[14px] font-normal text-[#525252]">{text}</span>
                </div>
                <button
                    type="button"
                    className="px-[66px] py-[10px] border
                     border-[#0068B5] hover:bg-[#0068B5]
                     transition-all duration-500 hover:text-white bg-transparent text-[#0068B5]"
                >
                    Details
                </button>
            </div>
        </div>
    )
}
