import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import AboutImage from '../../../static/images/aboutImage.jpg'
import HeroImage from '../../../static/images/discoverImg.jpg'
import { PersonCard } from './components/PersonCard'
import Amirshoh from '../../../static/images/amirshoh.png'
import Salohiddin from '../../../static/images/salohiddin.png'
import { Like, NotLike } from '../../../components/svgs/Svgs'
import BgGradient from '../../../static/images/bggradient.png'

const stats = [
    { value: '100+', label: 'Satisfied clients' },
    { value: '25+', label: 'Successfully completed projects' },
    { value: '2 YRS', label: 'Creative experience' },
    { value: '24/7', label: 'Customer support' },
    { value: '300%', label: 'Growth rate in recent years' },
    { value: '10', label: 'Partner organizations' },
]

// eslint-disable-next-line import/prefer-default-export
export const About = React.memo(() => {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        // Apply smooth scroll behavior to the document
        document.documentElement.style.scrollBehavior = 'smooth'

        // Smooth scroll to the top when the route changes
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location]) // Triggered when location (route) changes

    return (
        <div className="w-full flex flex-col px-[24px] lg:px-[20px] gap-[100px]">
            <section
                style={{ backgroundImage: `url(${BgGradient})` }}
            >
                <div className="flex flex-col pt-[38px] items-center gap-[15px]">
                    <span className="text-[28px] font-[300] text-[#262626]">About us</span>
                    <div className="flex items-center">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                        jsx-a11y/no-static-element-interactions */}
                        <span
                            onClick={() => navigate('/')}
                            className="text-[14px]
                         cursor-pointer font-[300] text-[#262626]"
                        >
                            Home
                        </span>
                        <span className="text-[14px] cursor-pointer
                        font-normal text-[#262626]"
                        >
                            {location.pathname}
                        </span>
                    </div>
                    <div className="w-[100%] sm:h-[179px] md:h-[250px] lg:h-[570px] lg:px-[40px]">
                        <img
                            src={AboutImage}
                            alt="img of the people
                        arguing each other"
                            loading="lazy"
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                </div>
            </section>
            <section className="w-full flex sm:flex-col lg:flex-row lg:items-center gap-[32px]">
                <div className="sm:w-full lg:w-[50%]">
                    <img
                        src={HeroImage}
                        alt="img of the building realsoft ai"
                        className="w-[100%] h-[100%] object-cover"
                    />
                </div>
                <div className="sm:w-full flex flex-col lg:w-[50%] items-start gap-[33px]">
                    <div className="flex flex-col items-start gap-[15px]">
                        <div className="flex flex-col items-start gap-[6px]">
                            <span className="text-[12px] font-normal text-[#0068B5]">Discover Realsoft AI</span>
                            <span className="text-[31px] font-[300]
                             text-[#262626] w-[80%]"
                            >
                                Our Story, Team, and Vision
                            </span>
                        </div>
                        <span className="text-[14px] font-normal text-[#262626] lg:w-[90%]">
                            Realsoft AI is an innovative company founded in 2024 with a
                            mission to lead in the field of artificial intelligence.
                            Within a short period, we have successfully
                            delivered impactful projects and provided clients with advanced AI solutions.
                            Our team consists of over 10 highly skilled developers with
                            deep expertise in programming and technology. At Realsoft AI,
                            our goal is to tackle complex challenges and create new opportunities
                            for the future through intelligent solutions.
                            We are committed to understanding the unique needs of our clients
                            and delivering services of the highest quality.
                        </span>
                    </div>
                </div>
            </section>
            <section>
                <div className="flex flex-col lg:px-[24px] items-start gap-[10px]">
                    <span className="text-[12px] lg:text-[16px]
                    font-normal text-[#0068B5]"
                    >
                        What do the numbers say about us?
                    </span>
                    <span className="text-[31px] font-[300]
                    text-[#262626]"
                    >
                        Pure Truth: Facts That Speak for Themselves
                    </span>
                </div>
                <div className="mx-auto px-4 py-16">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
                        {stats.map((stat, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="text-center relative">
                                <div className="text-[2.5rem] font-bold text-[#0068B5] mb-2">
                                    {stat.value}
                                </div>
                                <div className="text-gray-900 pb-[43px] text-lg">
                                    {stat.label}
                                </div>
                                {index < 5 && (
                                    <div className="absolute bottom-0 left-0 right-0 h-px bg-[#ECECEC] md:hidden" />
                                )}
                                {index % 3 !== 2 && (
                                    <div className="absolute top-0 bottom-0 right-0
                                    w-px bg-[#ECECEC] hidden md:block"
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section>
                <div className="w-full flex flex-col mb-[70px] gap-[50px]">
                    <div className="flex flex-col lg:px-[24px] items-start gap-[10px]">
                        <span className="text-[12px] font-normal text-[#0068B5]">OUR TEAM</span>
                        <span className="text-[31px] font-[300] text-[#262626]">A Team That Breaks Every Boundary</span>
                    </div>
                    <div className="grid sm:grid-cols-1 md:grid-cols-2  w-[100%] lg:px-[24px]
                     lg:grid-cols-3 xl:grid-cols-4 sm:gap-y-[30px] lg:gap-x-[30px] lg:gap-y-[50px]"
                    >
                        <PersonCard
                            img={Amirshoh}
                            personName="Kamoliddin Soliev"
                            bio="CEO and Co-founder"
                        />
                        <PersonCard
                            img={Amirshoh}
                            personName="Muxitdinov Amirshox"
                            bio="Backend developer"
                        />
                        <PersonCard
                            img={Amirshoh}
                            personName="Jamshid Abdullajonov"
                            bio="Backend developer"
                        />
                        <PersonCard
                            img={Salohiddin}
                            personName="Salohiddin Ruziyev"
                            bio="Software Engineer and C++ developer"
                        />
                        <PersonCard
                            img={Amirshoh}
                            personName="Aftondil Idiyev"
                            bio="Backend developer, MLops"
                        />
                        <PersonCard
                            img={Amirshoh}
                            personName="Qayumov Salohiddin"
                            bio="Data scientist"
                        />
                        <PersonCard
                            img={Amirshoh}
                            personName="Anvarov Mustafa"
                            bio="Frontend developer"
                        />
                        <PersonCard
                            img={Amirshoh}
                            personName="O`ktamov Shohjahon"
                            bio="Frontend developer"
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="w-full py-[28px] flex items-center gap-[21px] justify-center bg-[#F5F6F7] px-[24px]">
                    <span className="text-[#000000]
                    lg:text-[21px] sm:text-[14px] font-normal"
                    >
                        How is your experience with this page?
                    </span>
                    <div className="flex items-center gap-[12px]">
                        <Like />
                        <NotLike />
                    </div>
                </div>
            </section>
        </div>
    )
})
