import React from 'react'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function Card({ icon: IconComponent, title, bio, link }) {
    return (
        <div className="bg-[#404040] flex flex-col items-start gap-[25px] py-[35px] px-[24px]">
            {IconComponent && <IconComponent />}
            <div className="flex flex-col items-start gap-[10px]">
                <span className="text-[25px] font-normal text-white">{title}</span>
                <span className="text-[14px] font-normal text-white">{bio}</span>
            </div>
            <a href={link} target="_blank" rel="noreferrer">
                <button
                    type="button"
                    className="px-[47px] py-[10px] border
                border-white text-white font-normal text-[14px]"
                >
                    Open
                </button>
            </a>
        </div>
    )
}
