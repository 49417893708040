import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import HeroImage from '../../../static/images/discoverImg.jpg'
import { BusinessCard } from './components/BusinessCard'
import OneSystemLogo from '../../../static/images/onesystemLogo.png'
import UniPass from '../../../static/images/unipass.png'
import { PartnersCard } from './components/PartnersCard'
import OneSystemImage from '../../../static/images/onesystemImage.jpg'
import AIAdvokat from '../../../static/images/aiadvokat.jpg'

const usData = [
    {
        id: 1,
        img: OneSystemImage,
        title: 'OneSystem',
        text: 'Lorem ipsum dolor sit amet consectetur. Non arcu interdum nisl felis leo amet mattis egestas porttitor.',
    },
    {
        id: 2,
        img: null,
        title: 'UniPass',
        text: 'Lorem ipsum dolor sit amet consectetur. Non arcu interdum nisl felis leo amet mattis egestas porttitor.',
    },
    {
        id: 3,
        img: null,
        title: 'Vision Analytics',
        text: 'Lorem ipsum dolor sit amet consectetur. Non arcu interdum nisl felis leo amet mattis egestas porttitor.',
    },
    {
        id: 4,
        img: AIAdvokat,
        title: 'AI ADVOKAT',
        text: 'Lorem ipsum dolor sit amet consectetur. Non arcu interdum nisl felis leo amet mattis egestas porttitor.',
    },
]

// eslint-disable-next-line import/prefer-default-export
export const MainPage = React.memo(() => {
    const location = useLocation()

    useEffect(() => {
        // Apply smooth scroll behavior to the document
        document.documentElement.style.scrollBehavior = 'smooth'

        // Smooth scroll to the top when the route changes
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location]) // Triggered when location (route) changes

    return (
        <div className="w-full flex flex-col lg:px-[20px] gap-[100px]">
            {/* discover page */}
            <section
                className="w-full  flex sm:flex-col h-[100%] lg:flex-row pt-[38px] lg:items-center gap-[32px]"
            >
                <div className="sm:w-full lg:w-[50%]">
                    <img
                        src={HeroImage}
                        loading="lazy"
                        alt="img of the building realsoft ai"
                        className="w-[100%] h-[100%] object-cover"
                    />
                </div>
                <div className="sm:w-full flex flex-col lg:w-[50%] items-start px-[24px] gap-[33px]">
                    <div className="flex flex-col items-start gap-[15px]">
                        <div className="flex flex-col items-start gap-[6px]">
                            <span className="text-[12px] font-normal text-[#0068B5]">Discover Realsoft AI</span>
                            <span className="text-[31px]
                            font-[300] text-[#262626] w-[80%]"
                            >
                                Our Story, Team, and Vision
                            </span>
                        </div>
                        <span className="text-[14px] font-normal text-[#262626] lg:w-[90%]">
                            Realsoft AI is an innovative company founded in 2024 with
                            a mission to lead in the field of artificial intelligence.
                            Within a short period, we have successfully delivered impactful
                            projects and provided clients with advanced AI solutions.
                            Our team consists of over 10 highly skilled developers with deep
                            expertise in programming and technology. At Realsoft AI, our goal is
                            to tackle complex challenges and create new opportunities for the future
                            through intelligent solutions.
                            We are committed to understanding the unique needs of our clients and
                            delivering services of the highest quality.
                        </span>
                    </div>
                    <button
                        type="button"
                        className="text-white font-normal
                    text-[12px] px-[18px] py-[10px] bg-[#0068B5]"
                    >
                        Discover our expertise
                    </button>
                </div>
            </section>
            {/* cards page */}
            <section>
                <div className="flex flex-col sm:px-[24px] gap-[50px]">
                    <div className="flex flex-col items-start gap-[10px]">
                        <span className="text-[#0068B5]
                         font-normal text-[14px]"
                        >
                            What do the numbers say about us?
                        </span>
                        <span className="sm:text-[31px]
                        font-[300] text-[#262626]"
                        >
                            Customized Solutions for Your Business
                        </span>
                    </div>
                    <div className="grid  md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3 sm:gap-[30px] lg:gap-[70px]">
                        {usData.map((item) => (
                            <BusinessCard image={item.img} title={item.title} text={item.text} />
                        ))}
                    </div>
                </div>
            </section>
            {/* partners page */}
            <section>
                <div className="flex flex-col sm:gap-[24px] mb-[70px] sm:px-[24px] lg:gap-[50px]">
                    <div className="flex flex-col items-start gap-[5px]">
                        <span className="text-[14px] font-normal text-[#0068B5]">Our partners</span>
                        <span className="text-[31px] font-[300]
                        text-[#262626]"
                        >
                            We Value the Trust of Our Partners
                        </span>
                    </div>
                    <div className="flex sm:flex-col md:flex-row items-center gap-[14px] lg:gap-[30px]">
                        <div className="sm:py-[56px] lg:py-[93px]
                         sm:px-[50px] lg:px-[120px] bg-[#404040] flex flex-col items-center justify-center"
                        >
                            <span className="text-[91px] font-normal text-white">10</span>
                            <span className="text-[21px]
                            whitespace-nowrap font-normal text-white"
                            >
                                Partner organizations
                            </span>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 sm:gap-[14px] lg:gap-[30px]">
                            <PartnersCard img={OneSystemLogo} />
                            <PartnersCard img={UniPass} />
                            <PartnersCard img={UniPass} />
                            <PartnersCard img={UniPass} />
                            <PartnersCard img={UniPass} />
                            <PartnersCard img={UniPass} />
                            <PartnersCard img={UniPass} />
                            <PartnersCard img={UniPass} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
})
