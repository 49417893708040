import React from 'react'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function PersonCard({ img, personName, bio }) {
    return (
        <div className="flex flex-col items-center gap-[20px]">
            <div className="w-[320px] h-[360px]">
                <img src={img} alt="img of the person" className="w-[100%] h-[100%] object-cover" />
            </div>
            <div className="flex flex-col items-center gap-[7px]">
                <span className="text-[25px] font-[700] text-black">{personName}</span>
                <span className="text-[21px] text-center font-normal text-[#525252]">{bio}</span>
            </div>
        </div>
    )
}
