/* eslint-disable */
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card } from './components/card'
import { CallCenter, Comment, Email, FormEmail, map, Name, PhoneNumber } from '../../../components/svgs/Svgs'
import { Input } from './components/input'
import BgGradient from '../../../static/images/bggradient.png'

// eslint-disable-next-line import/prefer-default-export
export const Contact = React.memo(() => {
    const { register, handleSubmit, reset } = useForm()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'

        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location])

    const submit = () => {
        reset()
    }
    return (
        <div className="w-full flex flex-col px-[24px] lg:px-[20px] gap-[38px] lg:gap-[100px]">
            <section
                style={{
                    backgroundImage: `url(${BgGradient})`,
                }}
            >
                <div className="flex flex-col pt-[38px]  items-center gap-[15px]">
                    <span className="text-[28px] text-center font-[300] text-[#262626]">Contacts</span>
                    <div className="flex items-center">
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
                        jsx-a11y/click-events-have-key-events */}
                        <span
                            onClick={() => navigate('/')}
                            className="text-[14px] cursor-pointer font-[300] text-[#262626]"
                        >
                            Home
                        </span>
                        <span className="text-[14px] cursor-pointer
                        font-normal text-[#262626]"
                        >
                            {location.pathname}
                        </span>
                    </div>
                    <div className="w-[100%] sm:h-[179px]  md:h-[250px] lg:h-[570px]">
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe
                            style={{ border: 0 }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3006.311436211879!2d69.28723339793335!3d41.33256170848685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8bc81d43da97%3A0xa44255ba2a280e29!2sRealSoft!5e0!3m2!1sen!2s!4v1731560591189!5m2!1sen!2s"
                            width="100%"
                            height="100%"
                            allowFullScreen
                            loading="lazy"
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="grid grid-cols-1 lg:grid-cols-3 w-[100%]
                 md:w-[70%] lg:w-[100%] m-auto gap-[24px] lg:gap-[70px]"
                >
                    <Card
                        link="https://www.google.com/maps?ll=41.332563,69.28981&z=
                        17&t=m&hl=en&gl=US&mapclient=embed&cid=11836117028742630953"
                        icon={map}
                        title="Address"
                        bio="100084, O'zbekiston , Toshkent sh , Kichik Halqa yo'li , 38/1"
                    />
                    <Card
                        link="mailto:kamolsf8@gmail.com"
                        icon={Email}
                        title="Email"
                        bio="kamolsf8@gmail.com"
                    />
                    <Card
                        link="tel:+998996600893"
                        icon={CallCenter}
                        title="Call Center"
                        bio="+998 99 660 08 93"
                    />
                </div>
            </section>
            <section>
                <div className="w-[100%] mb-[70px] flex flex-col items-start gap-[18px]">
                    <span className="text-[34px] font-normal lg:font-[300] text-black">Contact us</span>
                    <form
                        onSubmit={handleSubmit(submit)}
                        className="w-[100%] flex flex-col items-center gap-[14px] lg:gap-[40px]"
                    >
                        <div className="flex sm:flex-col lg:flex-row lg:items-center lg:gap-[39px] gap-[14px] w-[100%]">
                            <Input
                                name="name"
                                register={register}
                                type="text"
                                placeholder="Name"
                                icon={Name}
                            />
                            <Input
                                name="phone"
                                register={register}
                                type="phone"
                                placeholder="Your phone number"
                                icon={PhoneNumber}
                            />
                            <Input
                                name="email"
                                register={register}
                                type="email"
                                placeholder="e-mail"
                                icon={FormEmail}
                            />
                        </div>
                        <div className="relative w-[100%] border lg:h-[300px] border-[#C2C2C2] p-4 bg-[#F9F9F9]">
                            <textarea
                                placeholder="Your comments"
                                className="w-[94%] h-[100%]  bg-transparent outline-none pr-10"
                                {...register('message', { required: true })}
                            />
                            <button
                                type="submit"
                                className="absolute w-[6%]
                            flex items-center justify-end top-4 right-4 text-blue-500 hover:text-blue-700"
                            >
                                <Comment />
                            </button>
                        </div>
                        <div className="flex items-center justify-start w-[100%]">
                            <button
                                type="button"
                                className="py-[10px] w-[100%]
                            lg:w-[15%] bg-[#0068B5] text-center text-white text-[14px] font-normal"
                            >
                                Send a message
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
})
