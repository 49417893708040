import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { ProductCard } from './components/ProductCard'
import ProductImage from '../../../static/images/productImage.webp'
import { Like, NotLike } from '../../../components/svgs/Svgs'
import OneSystemImage from '../../../static/images/onesystemImage.jpg'
import AIAdvokat from '../../../static/images/aiadvokat.jpg'
import BgGradient from '../../../static/images/bggradient.png'

// eslint-disable-next-line import/prefer-default-export
export const Product = React.memo(() => {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        // Apply smooth scroll behavior to the document
        document.documentElement.style.scrollBehavior = 'smooth'

        // Smooth scroll to the top when the route changes
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location]) // Triggered when location (route) changes

    return (
        <div className="w-full flex  flex-col px-[24px] lg:px-[20px] gap-[100px]">
            <section
                style={{
                    backgroundImage: `url(${BgGradient})`,
                }}
            >
                <div className="flex flex-col pt-[38px] items-center gap-[15px]">
                    <span className="text-[28px] text-center font-[300]
                     text-[#262626]"
                    >
                        Customized Solutions for Your Business
                    </span>
                    <div className="flex items-center">
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
                        jsx-a11y/click-events-have-key-events */}
                        <span
                            onClick={() => navigate('/')}
                            className="text-[14px] cursor-pointer font-[300] text-[#262626]"
                        >
                            Home
                        </span>
                        <span className="text-[14px] cursor-pointer
                         font-normal text-[#262626]"
                        >
                            {location.pathname}
                        </span>
                    </div>
                    <div className="w-[100%] sm:h-[179px] md:h-[250px] lg:h-[570px] lg:px-[40px]">
                        <img
                            src={ProductImage}
                            alt="img of the people arguing each other"
                            loading="lazy"
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="flex w-[100%] lg:mb-[110px] md:mb-[57px]
                lg:px-[24px] flex-col items-center sm:gap-[22px] md:gap-[100px] lg:gap-[120px]"
                >
                    <ProductCard
                        index="1"
                        image={OneSystemImage}
                        title="OneSystem"
                        text="Realsoft AI is an innovative company founded in 2024
                        with a mission to lead in the field of
                        artificial intelligence. Within a short period,
                        we have successfully delivered impactful projects and
                        provided clients with advanced AI solutions. Our team
                        consists of over 10 highly skilled developers with
                        deep expertise in programming and technology. At Realsoft AI,
                        our goal is to tackle complex challenges and
                        create new opportunities for the future through intelligent
                        solutions. We are committed to understanding the
                        unique needs of our clients and delivering services
                        of the highest quality."
                    />
                    <ProductCard
                        index="2"
                        image={null}
                        title="UniPass"
                        text="Realsoft AI is an innovative company founded in 2024
                        with a mission to lead in the field of
                        artificial intelligence. Within a short period,
                        we have successfully delivered impactful projects and
                        provided clients with advanced AI solutions. Our team
                        consists of over 10 highly skilled developers with
                        deep expertise in programming and technology. At Realsoft AI,
                        our goal is to tackle complex challenges and
                        create new opportunities for the future through intelligent
                        solutions. We are committed to understanding the
                        unique needs of our clients and delivering services
                        of the highest quality."
                    />
                    <ProductCard
                        index="3"
                        image={AIAdvokat}
                        title="AI ADVOKAT"
                        text="Realsoft AI is an innovative company founded in 2024
                        with a mission to lead in the field of
                        artificial intelligence. Within a short period,
                        we have successfully delivered impactful projects and
                        provided clients with advanced AI solutions. Our team
                        consists of over 10 highly skilled developers with
                        deep expertise in programming and technology. At Realsoft AI,
                        our goal is to tackle complex challenges and
                        create new opportunities for the future through intelligent
                        solutions. We are committed to understanding the
                        unique needs of our clients and delivering services
                        of the highest quality."
                    />
                    <ProductCard
                        index="4"
                        title="Vision Analytics"
                        text="Realsoft AI is an innovative company founded in 2024
                        with a mission to lead in the field of
                        artificial intelligence. Within a short period,
                        we have successfully delivered impactful projects and
                        provided clients with advanced AI solutions. Our team
                        consists of over 10 highly skilled developers with
                        deep expertise in programming and technology. At Realsoft AI,
                        our goal is to tackle complex challenges and
                        create new opportunities for the future through intelligent
                        solutions. We are committed to understanding the
                        unique needs of our clients and delivering services
                        of the highest quality."
                    />
                    <ProductCard
                        index="5"
                        title="ML Model hub"
                        text="Realsoft AI is an innovative company founded in 2024
                        with a mission to lead in the field of
                        artificial intelligence. Within a short period,
                        we have successfully delivered impactful projects and
                        provided clients with advanced AI solutions. Our team
                        consists of over 10 highly skilled developers with
                        deep expertise in programming and technology. At Realsoft AI,
                        our goal is to tackle complex challenges and
                        create new opportunities for the future through intelligent
                        solutions. We are committed to understanding the
                        unique needs of our clients and delivering services
                        of the highest quality."
                    />
                </div>
            </section>
            <section>
                <div className="w-full py-[28px] flex items-center gap-[21px] justify-center bg-[#F5F6F7] px-[24px]">
                    <span className="text-[#000000] lg:text-[21px]
                     sm:text-[14px] font-normal"
                    >
                        How is your experience with this page?
                    </span>
                    <div className="flex items-center gap-[12px]">
                        <Like />
                        <NotLike />
                    </div>
                </div>
            </section>
        </div>
    )
})
