import React, { useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../shared/utils/colors'
import { ZoomInIcon, ZoomOutIcon } from '../../../components/svgs/Svgs'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import Button from '../../../components/common/Button'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import { useGetRequest } from '../../../shared/hooks/requests'
import { SCAMERA_ZOOM, SCAMERA_GET_SNAPSHOT } from '../../../shared/utils/urls'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

export default function Controls({ item }) {
    const { handleErrorMsg } = useHandleErrors()
    const [rangeVal, setRangeVal] = useState(0)
    // eslint-disable-next-line max-len
    const [imageSrc, setImageSrc] = useState(item.snapshots.length && item.snapshots[item.snapshots.length - 1].snapshotUrl)
    const zoom = useGetRequest({ url: SCAMERA_ZOOM.replace('{id}', item.id) })
    const getSnapshotRequest = useGetRequest({ url: SCAMERA_GET_SNAPSHOT.replace('{id}', item.id) })

    const zoomInOut = async (type) => {
        if (zoom.loading) return

        const { success, response, error } = await zoom.request({ params: { zoomType: type } })

        if (success) {
            setImageSrc(response.imageUrl)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const getSnapshot = async () => {
        if (getSnapshot.loading) return

        const { success, response, error } = await getSnapshotRequest.request()

        if (success) {
            setImageSrc(response.snapshotUrl)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <div>
            <div className={cn(css(s.modalBody), 'w-full flex flex-col bg-white')}>
                <div className="justify-center rounded-m overflow-hidden h-[400px]">
                    <div className={bgStyle(imageSrc)} />
                </div>

                <div className={css(s.controlsCont)}>
                    {/* <div className={cn(css(s.playerToggle), 'flex-cent gap-2')}>
                        <h3 className="text-l font-500">Real-time Capture</h3>

                        <ToggleSwitch onClick={() => setAutoPlay(!autoPlay)} isChecked={autoPlay} />
                    </div> */}

                    <div className={cn(css(s.ctrlBtns), 'flex-col')}>
                        <div className={cn(css(s.zoomInOut), 'flex')}>
                            <Button onClick={() => zoomInOut('in')}>
                                {!zoom.loading ? <ZoomInIcon /> : <LoadingSpinner width={18} strokeWidth={3} />}
                            </Button>

                            <Button onClick={() => zoomInOut('out')}>
                                {!zoom.loading ? <ZoomOutIcon /> : <LoadingSpinner width={18} strokeWidth={3} />}
                            </Button>
                        </div>
                    </div>

                    <div className={cn(css(s.sect), 'flex-col gap-5 relative')}>
                        <h3 className={cn(css(s.floatingTitle), 'text-m font-500')}>PTZ speed</h3>

                        <input
                            min={0}
                            max={100}
                            type="range"
                            value={rangeVal}
                            style={{ width: '100%' }}
                            onChange={({ target }) => setRangeVal(target.value)}
                        />

                        <div className="w-full flex-cent">
                            <span className={cn(css(s.rangeValue), 'flex-cent')}>{rangeVal}</span>
                        </div>
                    </div>
                </div>
                <PrimaryBtn title="Get Snapshot" onClick={getSnapshot} />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        width: 1500,
        backgroundColor: COLORS.white,
        borderRadius: 8,
    },
    head: {
        backgroundColor: COLORS.mainBlueBg,
        padding: '13px 16px',
        color: COLORS.white,
        fontSize: 17,
        fontWeight: '500',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        ':nth-child(1n) > span': {
            cursor: 'pointer',
        },
    },
    modalBody: {
        gap: 43,
        maxHeight: 'calc(100vh - 120px)',
        minHeight: 'calc(100vh - 120px)',
        padding: '20px 22px',
        boxSizing: 'border-box',
    },
    video: {
        width: '100%',
        height: 'auto',
    },
    playerToggle: {
        paddingBottom: 14,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${COLORS.sepia}`,
    },
    gridItems: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gap: 20,
    },
    ctrlBtns: {
        padding: '30px 34px',
        gap: 35,
    },
    ctrlBtn: {
        padding: '5px 0',
        borderRadius: 5,
        border: `1px solid ${COLORS.sepia}`,
    },
    zoomInOut: {
        borderRadius: 10,
        background: COLORS.white,
        border: `1px solid ${COLORS.sepia}`,
        ':nth-child(1n) > *': {
            padding: '7px 0',
            flex: 1,
        },
        ':nth-child(1n) > :first-child': {
            borderRight: `1px solid ${COLORS.sepia}`,
        },
    },
    rangeValue: {
        border: `1px solid ${COLORS.sepia}`,
        width: 45,
        padding: '7px 0',
        borderRadius: 5,
    },
    floatingTitle: {
        position: 'absolute',
        top: -10,
        left: 15,
        padding: '0 10px',
        backgroundColor: COLORS.white,
    },
    sect: {
        borderTop: `1px solid ${COLORS.sepia}`,
        padding: '35px 0',
    },
})

const bgStyle = (url) => css(StyleSheet.create({
    bg: {
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '400px',
    },
}).bg)
