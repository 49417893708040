import React from 'react'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function PartnersCard({ img }) {
    return (
        <div className="sm:px-[23px] lg:px-[50px]
        flex flex-col items-center justify-center bg-[#262626] sm:h-[133px] lg:h-[162px]"
        >
            <div className="w-[100px] h-[58px]">
                <img src={img} alt="img of the card" className="w-[100%] h-[100%] object-contain" />
            </div>
        </div>
    )
}
