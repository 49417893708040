class WebSocketService {
    constructor(url, maxReconnectAttempts = 999) {
        this.url = url
        this.listeners = []
        this.maxReconnectAttempts = maxReconnectAttempts
        this.reconnectAttempts = 0
        this.reconnectExponentialDelay = 1000
        this.manualClose = false // Флаг для отслеживания ручного закрытия

        this.initializeWebSocket()
    }

    initializeWebSocket() {
        this.ws = new WebSocket(this.url)
        this.ws.onmessage = (message) => {
            const data = JSON.parse(message.data)
            this.listeners.forEach((listener) => listener(data))
        }

        this.ws.onclose = () => {
            if (!this.manualClose) {
                console.log('WebSocket closed unexpectedly. Attempting to reconnect...')
                this.attemptReconnect()
            } else {
                console.log('WebSocket closed manually.')
            }
        }

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }
    }

    attemptReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(() => {
                this.reconnectAttempts++
                console.log(`Reconnect attempt #${this.reconnectAttempts}`)
                this.initializeWebSocket()
            }, this.reconnectExponentialDelay * this.reconnectAttempts)
        } else {
            console.error('Max WebSocket reconnection attempts reached.')
        }
    }

    addListener(listener) {
        this.listeners.push(listener)
    }

    removeListener(listener) {
        this.listeners = this.listeners.filter((l) => l !== listener)
    }

    close() {
        this.manualClose = true
        if (this.ws) {
            this.ws.close()
        }
    }
}

export default WebSocketService
