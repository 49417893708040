import React, { useState } from 'react'
import { Box, Modal, TableCell } from '@mui/material'
import moment from 'moment'

export default function AttendanceTypeTableItem({ item }) {
    const [open, setOpen] = useState(false)
    const [modalImage, setModalImage] = useState(null)

    const handleOpen = (image) => {
        setModalImage(image)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setModalImage(null)
    }

    const entityNameFull = item.identity.tenantEntity ? item.identity.tenantEntity.name : '-'
    const entityName = item.identity.tenantEntity ? item.identity.tenantEntity.name.substring(0, 14) : '-'
    const isSpoofed = item.spoofing ? String(item.spoofing.isSpoofed) : '-'
    const spoofingScore = item.spoofing ? String(item.spoofing.score).substring(0, 5) : '-'
    return (
        <>
            <TableCell>
                <div className="w-[50px] rounded-[10px] overflow-hidden h-[50px]">
                    <img
                        src={item.identity.photo}
                        alt="indentity"
                        className="w-[100%] h-[100%] object-cover"
                        onClick={() => handleOpen(item.identity.photo)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </TableCell>
            <TableCell>
                <div className="w-[50px] rounded-[10px] overflow-hidden h-[50px]">
                    <img
                        src={item.snapshotUrl}
                        alt="Snapshot"
                        className="w-[100%] h-[100%] object-cover"
                        onClick={() => handleOpen(item.snapshotUrl)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </TableCell>
            <TableCell>
                {entityName}{entityNameFull.length > 15 && '...'}
            </TableCell>
            <TableCell>
                {item.identity.firstName}
            </TableCell>
            <TableCell>
                {item.identity.lastName}
            </TableCell>
            <TableCell>
                {item.identity.pinfl}
            </TableCell>
            <TableCell>
                {item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : ''}
            </TableCell>
            <TableCell>
                {item.compScore}
            </TableCell>
            <TableCell>
                {isSpoofed}
            </TableCell>
            <TableCell>
                {spoofingScore}
            </TableCell>
            <TableCell>
                {item.byMobile ? 'True' : 'False'}
            </TableCell>
            <TableCell>
                {item.attendanceType}
            </TableCell>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="enlarged-image"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    sx={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '200px',
                        maxHeight: '200px',
                        outline: 'none',
                    }}
                >
                    {modalImage && (
                        <img
                            src={modalImage}
                            alt="Enlarged view"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    )}
                </Box>
            </Modal>
        </>
    )
}
