/* eslint-disable */
import React from 'react'
import { useNavigate } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
function BurgerMenu({ open, close }) {
    const navigate = useNavigate()
    const handleNavigate = (path) => {
        navigate(path)
        close() // Close the modal after navigation
    }
    return (
        <div
            className={`fixed top-[56px] left-0 w-full h-[calc(100%-56px)] bg-white  overflow-y-auto
            transform transition-transform duration-300 ease-in-out ${open
            ? 'translate-y-0 z-40' : '-translate-y-full -z-40'
        }`}
        >
            <nav className="flex flex-col items-center w-full text-center py-8">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */}
                <div
                    onClick={() => handleNavigate('/about')}
                    className="py-[20px] px-[23px] w-[100%] border-[#D7D7D7]
                     border-y flex items-center justify-start"
                >
                    <span className="text-[#0068B5] text-lg">
                        ABOUT
                    </span>
                </div>
                <div
                    onClick={() => handleNavigate('/product')}
                    className="py-[20px] px-[23px] w-[100%] border-[#D7D7D7]
                     border-b flex items-center justify-start"
                >
                    <span className="text-[#0068B5] text-lg">
                        PRODUCT
                    </span>
                </div>
                <div
                    onClick={() => handleNavigate('/contact')}
                    className="py-[20px] px-[23px] w-[100%] border-[#D7D7D7]
                    border-b flex items-center justify-start"
                >
                    <span className="text-[#0068B5] text-lg">
                        CONTACTS
                    </span>
                </div>
            </nav>
        </div>
    )
}

export default BurgerMenu
