/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderLogo from '../../../../static/images/blueLogo.png'
import LangBtn from '../../../../static/images/langBtn.png'
import CancelBtn from '../../../../static/images/cancelBtn.png'
import BurgerBtn from '../../../../static/images/burgerBtn.png'
import BurgerMenu from './BurgerMenu'
import HeaderLogoLG from '../../../../static/images/blueLogoLG.png'
import LangSelector from '../LangSelector'

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export function Header({ open, setOpen }) {
    const navigate = useNavigate()
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
            <div className={`w-full pr-[10px] relative z-50 flex items-center 
            justify-between h-auto bg-white transition-shadow ${isScrolled
            ? 'fixed top-0 left-0 right-0 shadow-md' : ''
        }`}
            >
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div onClick={() => navigate('/')} className="w-[59px] cursor-pointer lg:hidden h-[56px]">
                    <img src={HeaderLogo} alt="logo of the header" className="w-[100%] h-[100%]" />
                </div>
                <div onClick={() => navigate('/')} className="w-[237px] cursor-pointer hidden lg:block h-[69px]">
                    <img src={HeaderLogoLG} alt="logo of the header" className="w-[100%] h-[100%]" />
                </div>
                <div className="py-[18px] lg:hidden sm:flex items-center gap-[12px]">
                    <div className="w-[20px] h-[20px]">
                        <img src={LangBtn} alt="logo of the lang" className="w-[100%] h-[100%]" />
                    </div>
                    <div onClick={() => setOpen(!open)} className="w-[20px] h-[20px]">
                        {open ? (
                            <img src={CancelBtn} alt="img of the cancel" className="w-[100%] h-[100%]" />
                        ) : (
                            <img src={BurgerBtn} alt="img of the cancel" className="w-[100%] h-[100%]" />
                        )}
                    </div>
                </div>
                <div className="hidden lg:flex items-center gap-[32px]">
                    <span onClick={() => navigate('/about')} className="text-[14px] cursor-pointer font-normal text-[#0068B5]">ABOUT</span>
                    <span onClick={() => navigate('/product')} className="text-[14px] cursor-pointer font-normal text-[#0068B5]">PRODUCTS</span>
                    <span onClick={() => navigate('/contact')} className="text-[14px] cursor-pointer font-normal text-[#0068B5]">CONTACTS</span>
                </div>
                <div className="hidden lg:flex items-center gap-[10px]">
                    <LangSelector />
                    <button onClick={() => navigate('/auth')} className="px-[21px] py-[10px] bg-[#0068B5] text-white font-normal text-[14px]">LOGIN</button>
                </div>
            </div>

            <div className="w-[100%] lg:hidden">
                <BurgerMenu open={open} close={() => setOpen(false)} />
            </div>

            {/* Add padding top to main content to prevent hiding under fixed header */}
            <div className={isScrolled ? 'pt-[70px]' : ''}>
                {/* Page content goes here */}
            </div>
        </>
    )
}
