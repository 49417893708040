export const AUTH = 'token'

// TENANT ADMIN URLS

export const TENANT_ENTITIES = 'tenant/tenant_entity/'
export const TENANT_ENTITIES_ALL = 'tenant/tenant_entity/all'
export const TENANT_ENTITY_FILTER = 'tenant/tenant_entity/for_filter/all'
export const TENANT_ENTITIES_CHILDREN = 'tenant/tenant_entity/{id}/children'
export const BUILDINGS_TENANT = 'tenant/infrastructure/building/list'
export const BUILDINGS_TENANT_PAGINATED = 'tenant/infrastructure/building'
export const ROOMS_TENANT = 'tenant/infrastructure/room'
export const ROOMS_TENANT_UNPAGINATED = 'tenant/infrastructure/room/list'
export const USER_ROLES = 'tenant/user/roles'
export const TENANT_USER = 'tenant/user/{id}'
export const IDENTITIES = 'tenant/identity/{id}'
export const SMARTCAMERAS_TENANT = 'tenant/infrastructure/smartcamera'
export const SMARTCAMERA_GET_FACE_CONFIG = 'tenant/infrastructure/smartcamera/get_face_config'
export const SMARTCAMERA_SET_FACE_CONFIG = 'tenant/infrastructure/smartcamera/set_face_config'
export const CAMERAS_TENANT = 'tenant/infrastructure/camera'
export const SCAMERA_USERS = 'tenant/user/by_smart_camera'
export const SCAMERA_IDENTITIES = 'tenant/identity/by_smart_camera'
export const SYNC_IDENTITIES_SCAMERA = 'tenant/tenant_entity/sync/scamera'
export const UPLOAD_IDENTITIES_SCAMERA = 'tenant/identity/add_scamera_by_task'
export const USERS_BY_ENTITY = 'tenant/user/tenant_entity/'
export const IDENTITY_BY_ENTITY = 'tenant/identity/by_entity/'
export const IDENTITY_LIST = 'tenant/identity/'
export const UNLOADED_IDENTITY_LIST = 'tenant/identity/smartcamera/unloaded_identities'
export const LOADED_IDENTITY_LIST = 'tenant/identity/smartcamera/loaded_identities'
export const ADD_USER_SMARTCAMERA = 'tenant/user/smartcamera'
export const ADD_IDENTITY_SMARTCAMERA = 'tenant/identity/smartcamera'
export const CAMERA_USERS = 'tenant/user/by_camera/{id}'
export const ROI_LIST = 'tenant/nvdsanalytics/roi'
export const LINE_LIST = 'tenant/nvdsanalytics/line'
export const CAMERA_ROI = 'tenant/nvdsanalytics/camera/{id}/points'
export const CAMERA_LINES = 'tenant/nvdsanalytics/camera/{id}/lines'
export const SCAMERA_ZOOM = 'tenant/infrastructure/smartcamera/zoom/{id}'
export const SCAMERA_UPDATE_PASS = 'tenant/infrastructure/smartcamera/update/password/{id}'
export const SCAMERA_PROFILES = 'tenant/infrastructure/smartcamera/profile'
export const SCAMERA_WANTED_LIST = 'tenant/wanted/'
export const API_INTEGRATIONS = 'tenant/integrations/'
export const ADMIN_MODULES = 'tenant/tenant_entity/available/modules'
export const ENABLE_RTMP = 'tenant/infrastructure/smartcamera/rtmp_enable/{id}'
export const JETSON_PROFILE_LIST = 'tenant/infrastructure/jetson_profile/all'
export const JETSON_PROFILE = 'tenant/infrastructure/jetson_profile'
export const JETSON_DEVICES = 'tenant/infrastructure/jetson'
export const JETSON_DEVICES_BY_BUILDING = 'tenant/infrastructure/jetson/by_building/{id}'
export const JETSON_SETUP_CONFIG = 'tenant/infrastructure/jetson/setup_config'
export const JETSON_DEVICES_LIST = 'tenant/infrastructure/jetson_list'
export const JETSON_ACTIVE_DEVICES_LIST = 'tenant/jetson_device_manager/enlist_active_jetson_devices'
export const ACTIVE_CAMERAS_IP = 'tenant/jetson_device_manager/enlist_active_cameras'
export const NETWORK_INTERFACES = 'tenant/jetson_device_manager/enlist_network_interfaces'
export const CAMERA_ATTACH_JETSON = 'tenant/infrastructure/jetson/camera_attachment'
export const CAMERA_LIST = 'tenant/infrastructure/camera'
export const JETSON_DEVICE_ITEM = 'tenant/infrastructure/jetson/{id}'
export const JETSON_DEVICE_STREAM = 'tenant/infrastructure/jetson_device_stream/{id}'
export const JETSON_ATTACHED_CAMERAS = 'tenant/infrastructure/jetson/{id}/camera_attachment/list'
export const JETSON_DEVICE_SNAPSHOTS = 'tenant/jetson_device_manager/get_snapshot'
export const JETSON_DEVICE_DEEPSTREAM_APPS = 'tenant/jetson_device_manager/enlist_deepstream_applications'
export const JETSON_DEVICE_DEEPSTREAM_APP_CREATE = 'tenant/jetson_device_manager/add_deepstream_app'
export const RUN_DEEPSTREAM_APP = 'tenant/jetson_device_manager/run_deepstream_app'
export const STOP_DEEPSTREAM_APP = 'tenant/jetson_device_manager/stop_deepstream_app'
export const CUSTOMER_USER_ACTIVATION_LINK = 'tenant/user_activation_code/'
export const DEEPSTREAM_CONFIGS = 'tenant/jetson_device_manager/enlist_application_configurations'
export const UPDATE_DEEPSTREAM_CAMERAS = 'tenant/jetson_device_manager/update_deepstream_app_cameras'
export const UPDATE_DEEPSTREAM_ROI = 'tenant/jetson_device_manager/update_deepstream_app_roi_stream'
export const UPDATE_DEEPSTREAM_LINE = 'tenant/jetson_device_manager/update_deepstream_app_line_stream'
export const ADD_DEEPSTREAM_CONFIG_GROUP = 'tenant/jetson_device_manager/update_config/add_config_group'
export const DELETE_DEEPSTREAM_CONFIG_GROUP = 'tenant/jetson_device_manager/update_config/remove_config_group'
export const ADD_DEEPSTREAM_CONFIG_PROPERTY = 'tenant/jetson_device_manager/update_config/add_config_property'
export const UPDATE_DEEPSTREAM_CONFIG_PROPERTY = 'tenant/jetson_device_manager/update_config/replace_config_property'
export const DELETE_DEEPSTREAM_CONFIG_PROPERTY = 'tenant/jetson_device_manager/update_config/remove_config_property'
export const SMART_CAMERA_PROFILE = 'tenant/infrastructure/smartcamera/profile'
export const VPN_SETUP_CONFIG = 'tenant/openvpn/setup_config'
export const SMART_CAMERA_ANALYTICS = 'tenant/infrastructure/smartcamera/analytics'
export const IPSETTING = 'tenant/infrastructure/smartcamera/get_wired_network'
export const SERVICECONFIG = 'tenant/infrastructure/smartcamera/get_platform_server'
export const VPN = 'tenant/infrastructure/smartcamera/get_vpn_conf'
export const TIMERESTART = 'tenant/infrastructure/smartcamera/get_reboot_conf'
export const SETIPSETTING = 'tenant/infrastructure/smartcamera/set_wired_network'
export const SETSERVICECONFIG = 'tenant/infrastructure/smartcamera/set_platform_server'
export const SETVPN = 'tenant/infrastructure/smartcamera/set_vpn_conf'
export const SETTIMERESTART = 'tenant/infrastructure/smartcamera/set_reboot_conf'

export const FIRMWARE_POST_WITH_FILE = 'tenant/infrastructure/smartcamera/firmware_by_file'
export const FIRMWARE_URL = 'tenant/infrastructure/smartcamera/firmware'
export const PROFILE_FIRMWARE = 'tenant/infrastructure/smartcamera/profile_firmware'
export const ATTENDACE_TYPE = 'tenant/attendances'
export const ATTENDACE_TYPE_CUSTOMER = 'customer/attendances'
export const GET_INFO = 'tenant/customer_user_me'
export const ALLOWED_REGIONS = 'sysadmin/region/allowed-regions'
export const TENANT_CHILD = 'tenant/tenant_entity/'

// SYSTEM ADMIN URLS

export const TENANT_PROFILE = 'sysadmin/tenant_profile'
export const MODULES = 'sysadmin/module/'
export const USER_ROLE_CREATE = 'sysadmin/role_module_permission/by_permission_list'
export const VERIFY_ADMIN = 'sysadmin/tenant_admin/activate'
export const TENANTS = 'sysadmin/tenant'
export const TENANT_ADMIN = 'sysadmin/tenant_admin/{id}'
export const TENANT_ADMINS_LIST = 'sysadmin/tenant_admin/all/{id}'
export const TENANT_ADMIN_INACTIVE = 'sysadmin/tenant_admin/create_in_active'
export const TENANT_ADMIN_ACTIVATION_CODE = 'sysadmin/tenant_admin_activation_code'
export const FILE_UPLOAD = 'sysadmin/file_manager/upload_file'
export const COUNTRIES_LIST = 'sysadmin/region/country'
export const REGIONS_LIST = 'sysadmin/region/'
export const DISTRICTS_LIST = 'sysadmin/region/district'
export const ML_MODEL_PROFILES = 'sysadmin/ml_model/profile'
export const ML_MODELS = 'sysadmin/ml_model/'
export const ML_MODEL_UPLOAD_PIPELINE = 'sysadmin/ml_model/upload_model'
export const ML_MODEL_UPLOAD_CONFIG = 'sysadmin/ml_model/upload_readme'
export const ATTACH_MODULES_TO_TENANT_PROFILE = 'sysadmin/tenant_profile/module/list'
export const TENANT_ENTITY = 'tenant/tenant_entity/for_filter/all'

// CUSTOMER USER URLS
export const TENANT_ENTITY_CUSTOMER_FILTER = 'customer/tenant_entity_customer/for_filter'
export const SMARTCAMERAS_LIST = 'customer/infrastructure-customer/smartcamera'
export const ADMIN_INFO = 'tenant/tenant_admin_me'
export const CUSTOMER_INFO = 'customer/customer_user_me'
export const BUILDINGS_LIST = 'customer/infrastructure-customer/building'
export const BRANCHS_LIST = 'customer/dashboard/analytics/visitor_comparison'
export const VISITS_LIST = 'customer/dashboard/analytics/visits'
export const DAILY_GENDER_LIST = 'customer/dashboard/analytics/visits/daily-breakdown'
export const HOURLY_VISITS_LIST = 'customer/dashboard/analytics/visits/hourly-breakdown'
export const ENITITY_LIST = 'customer/tenant_entity_customer/'
export const AGE_GENDER_LIST = 'customer/dashboard/analytics/visits/age-gender-breakdown'
export const ROOMS_LIST = 'customer/infrastructure-customer/room'
export const CUSTOMER_IDENTITY_LIST = 'customer/identity/'
export const ALL_STATISTICS = 'v1/attendances/overall-statistics'
export const VISITOR_ATTENDANCES = 'v1/attendances/visitors'
export const USER_ATTENDANCES = 'v1/attendances/user-attendances'
export const IDENTITY_ATTENDANCES = 'v1/attendances/identity-attendances'
export const TEMP_ATTENDANCES = 'customer/attendance/'
export const ENABLE_STREAM = 'customer/infrastructure-customer/smartcamera/rtmp_enable/{id}'
export const STREAM_ANALYTICS_NOTIFICATIONS = 'customer/deepstream_analytics/notification_center/{id}'

// FACE AUTH API

export const CLIENTSPAGINATION = 'admin/client/select'
export const CLIENTS = 'admin/client/'
export const MASTER_USERSPAGINATION = 'client/master_user/select'
export const MASTER_USERS = 'client/master_user/'
export const USER_PAGINATION = 'master_user/user/select'
export const USERS = 'master_user/user'
export const AUTHLOGPAGINATION = 'admin/authorizations_logs/'
export const IMAGE_UPLOADER = 'admin/photo/change'
export const REVIEW_PHOTOS = 'admin/photo/'
export const PHOTO_STATUS = 'admin/photo/review'

// NEW ONES ( TODO: add categories)

export const WORKSPACE_ANALYTICS_LIST = 'customer/deepstream_analytics/work_space/daily_report/all'
export const WORKSPACE_ANALYTICS_SUMMARY = 'customer/deepstream_analytics/work_space/summary_report'
export const WORKSPACE_ANALYTICS_DAILY_REPORT = 'customer/deepstream_analytics/work_space/daily_report'
export const LINECROSSING_ANALYTICS_DAILY_REPORT = 'customer/deepstream_analytics/line_crossing/daily_report'
export const ANALYTICS_HISTORY = 'customer/deepstream_analytics/analytics_history/daily_report'
export const NO_REGISTERED_CAMERAS = 'tenant/infrastructure/smartcamera/not_created_list'
export const SCAMERA_GET_SNAPSHOT = 'tenant/infrastructure/smartcamera/realtime/snapshot/{id}'
